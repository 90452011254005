import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';

interface JsonLdProps {
  title: string;
  description: string;
  featuredImage?: string | null;
  date?: string;
  update?: string;
}

const JsonLd = ({
  title,
  description,
  featuredImage = null,
  date = '2022-06-07T08:00:00+08:00',
  update = '2022-06-07T08:00:00+08:00',
}: JsonLdProps) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery<GatsbyTypes.MetadataQuery>(query);

  const { siteUrl, image } = site.siteMetadata;

  const jsonLdStructuredData = {
    '@context': 'https://schema.org/',
    '@type': 'Corporation',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': siteUrl + '/', // 適宜調節してください。
    },
    author: {
      '@type': 'Organization',
      name: 'ChokerJoker',
      url: siteUrl,
      image: 'https://chokerjoker.com/icons/icon-96x96.png',
    },
    publisher: {
      '@type': 'Organization',
      name: 'ChokerJoker',
      url: siteUrl,
      logo: {
        '@type': 'ImageObject',
        url: 'https://chokerjoker.com/icons/icon-256x256.png',
        width: 256,
        height: 256,
      },
    },
    headline: title,
    image: featuredImage ? featuredImage : image,
    url: `${siteUrl}${pathname}`,
    description: description,
    datePublished: date,
    dateCreated: date,
    dateModified: update,
  };
  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(jsonLdStructuredData)}
      </script>
    </Helmet>
  );
};

export default JsonLd;

const query = graphql`
  query Metadata {
    site {
      siteMetadata {
        siteUrl
        image
      }
    }
  }
`;
