import * as React from 'react';
import { globalHistory } from '@reach/router';
import { navigate } from 'gatsby';
import {
  Box,
  Flex,
  IconButton,
  Text,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerCloseButton,
  Divider,
  useBreakpointValue,
  Heading,
} from '@chakra-ui/react';
import { CgMenuBoxed } from 'react-icons/cg';
import { BsInstagram } from 'react-icons/bs';
import { StaticImage } from 'gatsby-plugin-image';

const SNS = () => {
  const sendToInstagram = () => {
    if (typeof window !== `undefined`) {
      window.open(
        'https://www.instagram.com/chokerjoker.japan/?hl=ja',
        '_blank'
      );
    }
  };
  return (
    <Flex position="absolute" bottom="40%" left="5%" gap={{ base: 6, md: 12 }}>
      <IconButton
        aria-label="instagram"
        icon={<BsInstagram />}
        fontSize={{ base: 40, md: 50, xl: 60 }}
        bg="none"
        color="purple.200"
        onClick={sendToInstagram}
      />
    </Flex>
  );
};

const StoreButton = () => (
  <Text
    as="button"
    border="1px white solid"
    px={{ base: 4, md: 8 }}
    py={2}
    color="yellow"
    mr={{ base: 2, md: 8 }}
    fontWeight="bold"
    fontSize={{ base: 'xl', md: '4xl', xl: '6xl' }}
    _hover={{
      cursor: 'pointer',
    }}
    onClick={() => navigate('/store')}
  >
    Store
  </Text>
);

const StoreButtonSP = () => (
  <Text
    as="button"
    border="1px white solid"
    px={2}
    py={1}
    color="yellow"
    fontWeight="bold"
    fontSize="xl"
    _hover={{
      cursor: 'pointer',
    }}
    onClick={() => navigate('/store')}
    position="absolute"
    bottom="10%"
    right="3%"
  >
    Store
  </Text>
);

const StoreArrow = () => {
  return (
    <Box position="absolute" bottom={{ base: '15%', md: '5%' }} right={0}>
      <StoreButton />
    </Box>
  );
};

type HeaderMainProps = {
  children: React.ReactNode;
  isMobile: boolean | undefined;
  pathname: string;
};
const HeaderMain = ({ children, isMobile, pathname }: HeaderMainProps) => (
  <Box bg="black">
    <Box w={isMobile ? 'full' : '80%'} mx="auto" position="relative" pb={12}>
      {pathname === '/' ? (
        <StaticImage src="../images/header_pc.png" alt="" layout="fullWidth" />
      ) : isMobile ? (
        <StaticImage src="../images/header_sp.png" alt="" layout="fullWidth" />
      ) : (
        <StaticImage src="../images/header_pc.png" alt="" layout="fullWidth" />
      )}
      {children}
    </Box>
  </Box>
);

type PCMenuProps = {
  menuItems: { title: string; to: string }[];
};

const PCMenu = ({ menuItems }: PCMenuProps) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box bg="black">
      <Box px={4} w={isMobile ? 'full' : '80%'} mx="auto">
        <Flex
          mx="auto"
          justify="space-between"
          alignItems="center"
          px={8}
          py={8}
          borderBottom="2px solid white"
        >
          {menuItems.map((item, index) => (
            <Text
              key={item.title}
              w="full"
              color="white"
              fontWeight="bold"
              fontSize={{ base: 'xl', md: '2xl' }}
              textAlign="center"
              px={8}
              borderRight={
                index !== menuItems.length - 1 ? '1px solid white' : 'none'
              }
              _hover={{
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => navigate(item.to)}
            >
              {item.title}
            </Text>
          ))}
        </Flex>
      </Box>
    </Box>
  );
};

const Header = () => {
  const pathname = globalHistory.location.pathname;
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { isOpen, onOpen, onClose } = useDisclosure();
  let menuItems = [
    {
      title: 'ホーム',
      to: '/',
    },
    {
      title: 'ストア↓',
      to: '/store',
    },
    {
      title: `What's CJ`,
      to: '/whats-cj',
    },
    {
      title: 'Others',
      to: '/others',
    },
    {
      title: 'お支払/送料',
      to: '/about-shipping',
    },
    {
      title: `CJ's Team`,
      to: '/team',
    },
    {
      title: 'カート',
      to: '/cart',
    },
  ];

  if (isMobile) {
    menuItems.push({
      title: 'Instagram',
      to: 'https://www.instagram.com/chokerjoker.japan/?hl=ja',
    });
  }

  const handleMenuClick = (to: string) => {
    onClose();
    navigate(to);
  };

  return (
    <>
      {pathname === '/' && isMobile ? (
        <>
          <HeaderMain isMobile={isMobile} pathname={pathname}>
            <IconButton
              aria-label="menu"
              icon={<CgMenuBoxed />}
              fontSize={30}
              bg="none"
              color="white"
              onClick={onOpen}
              _hover={{
                bg: 'none',
              }}
              w={40}
              position="absolute"
              top="18%"
              left="-5%"
            />
            <Text
              w="100%"
              textAlign="center"
              color="white"
              fontSize="sm"
              fontWeight="bold"
              position="absolute"
              top={2}
              left="50%"
              transform="translateX(-50%)"
            >
              国内外パーツセレクトのオリジナルチョーカー専門店です
            </Text>
            <StoreArrow />
          </HeaderMain>
        </>
      ) : isMobile ? (
        <HeaderMain isMobile={isMobile} pathname={pathname}>
          <IconButton
            aria-label="menu"
            icon={<CgMenuBoxed />}
            fontSize="5xl"
            bg="none"
            color="white"
            onClick={onOpen}
            _hover={{
              bg: 'none',
            }}
            w={40}
            position="absolute"
            top="16%"
            left="-5%"
          />
          {pathname === '/watch-deco' ? null : <StoreButtonSP />}
        </HeaderMain>
      ) : (
        <>
          <HeaderMain isMobile={isMobile} pathname={pathname}>
            <Text
              w="100%"
              textAlign="center"
              color="white"
              fontSize={{ base: '3xl', md: '4xl', xl: '5xl' }}
              fontWeight="bold"
              position="absolute"
              top={8}
              left="50%"
              transform="translateX(-50%)"
            >
              国内外パーツセレクトのオリジナルチョーカー専門店です
            </Text>
            <SNS />
            {pathname === '/watch-deco' ? null : <StoreArrow />}
          </HeaderMain>
          <PCMenu menuItems={menuItems} />
        </>
      )}
      <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            <Heading>メニュー</Heading>
          </DrawerHeader>
          <DrawerBody>
            {menuItems.map((item, i) => (
              <Box
                key={i}
                py={2}
                onClick={() => handleMenuClick(item.to)}
                _hover={{ cursor: 'pointer' }}
              >
                <p>{item.title}</p>
                <Divider />
              </Box>
            ))}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Header;
