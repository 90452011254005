import * as React from 'react';
import {
  Text,
  Box,
  Flex,
  Icon,
  useBreakpointValue,
  Grid,
} from '@chakra-ui/react';
import { MdLocalShipping } from 'react-icons/md';
import { Link } from 'gatsby';
import { GiPresent } from 'react-icons/gi';
import { AiFillLock } from 'react-icons/ai';
import { StaticImage } from 'gatsby-plugin-image';
import { maxW } from '../assets/config';

const Footer = ({ fmb }: { fmb: number }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box as="footer" mb={fmb}>
      <Box position="relative">
        <StaticImage alt="footer" src="../images/footer_high.png" />
        <Flex
          w="full"
          px={10}
          direction="column"
          position="absolute"
          top={{ base: '-10%', md: '10%' }}
          left="50%"
          transform="translate(-50%, -50%)"
          maxW={maxW}
          mx="auto"
        >
          <Flex
            justify="space-between"
            fontWeight="bold"
            gap={20}
            mb={{ base: 4, md: 20 }}
          >
            <Flex direction="column" justify="center" alignItems="center">
              <Icon
                fontSize={{ base: '3rem', md: '5rem' }}
                as={MdLocalShipping}
              />
              <Text fontSize={{ base: 'xs', md: '4xl' }} textAlign="center">
                送料: 全国一律370円
              </Text>
            </Flex>
            <Flex
              direction="column"
              justify="center"
              alignItems="center"
              textAlign="center"
            >
              <Icon fontSize={{ base: '3rem', md: '5rem' }} as={GiPresent} />
              <Text fontSize={{ base: 'xs', md: '4xl' }} textAlign="center">
                5,000円以上の
                <br />
                お買い上げで送料無料
              </Text>
            </Flex>
            <Flex direction="column" justify="center" alignItems="center">
              <Icon fontSize={{ base: '3rem', md: '5rem' }} as={AiFillLock} />
              <Text fontSize={{ base: 'xs', md: '4xl' }} textAlign="center">
                安全な
                <br />
                オンライン決済を保証
              </Text>
            </Flex>
          </Flex>
          <Grid
            templateColumns={isMobile ? 'repeat(3, 1fr)' : 'repeat(3, 1fr)'}
            fontWeight="bold"
            textAlign="center"
          >
            <Text fontSize={{ base: 'xs', md: '2xl' }} textAlign="center">
              <Link to="/privacy">プライバシーポリシー</Link>
            </Text>
            <Text fontSize={{ base: 'xs', md: '2xl' }} textAlign="center">
              <Link to="/law">
                特定商取引法に{isMobile ? <br /> : null}基づく表記
              </Link>
            </Text>
            <Text fontSize={{ base: 'xs', md: '2xl' }} textAlign="center">
              <Link to="/contact">お問合わせ</Link>
            </Text>
          </Grid>
        </Flex>
        <Box
          w="full"
          position="absolute"
          top="85%"
          left="50%"
          transform="translate(-50%, -50%)"
        >
          <Text
            fontSize={{ base: 'sm', md: '4xl' }}
            fontWeight="bold"
            textAlign="center"
            color="white"
          >
            <a href="mailto:info@chokerjoker.com">
              <u>info@chokerjoker.com</u>
            </a>
            {` / `}
            {new Date().getFullYear()}, Powered by
            {` `}©
            <span>
              JohnWORKS
            </span>
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
