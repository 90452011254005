import React from 'react';
import { Helmet } from 'react-helmet';

const HeadTags = () => (
  <Helmet>
    {/* Google Fonts */}
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    {/* @ts-ignore */}
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
    <link
      href="https://fonts.googleapis.com/css2?family=DotGothic16&display=swap"
      rel="stylesheet"
    />
    {/* Budoux */}
    <script
      type="module"
      src="https://unpkg.com/budoux/bundle/budoux-ja.min.js"
    ></script>
  </Helmet>
);

export default HeadTags;
