import { Box } from '@chakra-ui/react';
import * as React from 'react';
import Footer from './Footer';

import Header from './header';
import HeadTags from './HeadTags';

type LayoutProps = {
  children: React.ReactNode;
  full?: boolean;
  mb?: number;
  fmb?: number;
  bg?: 'black' | 'white';
};

const Layout = ({
  children,
  full = false,
  mb = 40,
  fmb = 0,
  bg = 'white',
}: LayoutProps) => {
  return (
    <>
      <Header />
      <HeadTags />
      <Box bg={bg}>
        <Box
          m="0 auto"
          maxW={full ? '100%' : '1280px'}
          px={full ? 0 : 4}
          mb={mb}
        >
          <main>{children}</main>
        </Box>
      </Box>
      <Footer fmb={fmb} />
    </>
  );
};

export default Layout;
